var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    _vm._l(_vm.items, function(item) {
      return _vm.checkPermission(item.permission)
        ? _c(_vm.resolveNavItemComponent(item), {
            key: item.header || item.title,
            ref: "navBar",
            refInFor: true,
            tag: "component",
            attrs: { item: item }
          })
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }