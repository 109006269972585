var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "nav-item",
      class: {
        active: _vm.isActive,
        disabled: _vm.item.disabled
      }
    },
    [
      _c(
        "b-link",
        _vm._b(
          {
            directives: [
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.left",
                value: _vm.item.title,
                expression: "item.title",
                modifiers: { hover: true, left: true }
              }
            ],
            staticClass: "d-flex align-items-center"
          },
          "b-link",
          _vm.linkProps,
          false
        ),
        [
          _c("feather-icon", {
            attrs: { icon: _vm.item.icon || "CircleIcon" }
          }),
          _c("span", { staticClass: "menu-title text-truncate" }, [
            _vm._v(_vm._s(_vm.item.title))
          ]),
          _vm.item.tag
            ? _c(
                "b-badge",
                {
                  staticClass: "mr-1 ml-auto",
                  attrs: { pill: "", variant: _vm.item.tagVariant || "primary" }
                },
                [_vm._v(" " + _vm._s(_vm.item.tag) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }