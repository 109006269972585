var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-notification",
      staticStyle: { "list-style-type": "none" },
      attrs: { "menu-class": "dropdown-menu-media", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c("feather-icon", {
                staticClass: "text-body",
                attrs: {
                  badge: _vm.un_read_notification.length,
                  "badge-classes": "bg-danger",
                  icon: "BellIcon",
                  size: "21"
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("li", { staticClass: "dropdown-menu-header" }, [
        _c("div", { staticClass: "dropdown-header d-flex" }, [
          _c(
            "h3",
            {
              staticClass:
                "notification-title m-1 mr-auto font-weight-bolder dark"
            },
            [_vm._v(" Notifications ")]
          )
        ])
      ]),
      _vm.un_read_notification.length > 0
        ? _c(
            "vue-perfect-scrollbar",
            {
              staticClass: "scrollable-container media-list scroll-area",
              staticStyle: {
                height: "357px",
                border: "1px solid #E5E5E5",
                "border-radius": "4px"
              },
              attrs: { show: _vm.formLoading, tagname: "li" }
            },
            [
              _c("ul", { staticClass: "list-group" }, [
                _c(
                  "li",
                  _vm._l(_vm.un_read_notification, function(notification) {
                    return _c("b-media", { key: notification.id }, [
                      _c(
                        "div",
                        { staticClass: "notificationBar" },
                        [
                          _c(
                            "b-row",
                            {
                              staticClass: "mt-n1 ml-0 mr-0",
                              on: {
                                click: function($event) {
                                  return _vm.ReadNotification(notification.id)
                                }
                              }
                            },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "pb-0 pt-1",
                                  attrs: { cols: "10" }
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-start"
                                      },
                                      [
                                        _c(
                                          "b-avatar",
                                          {
                                            staticClass: "ml-1",
                                            attrs: {
                                              size: "32",
                                              variant: "light-info"
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: notification.icon,
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-1" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mb-0 font-weight-bolder",
                                              class: _vm.text_color1
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    notification.data.message
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          notification.created_at
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.momentFormat(
                                                          notification.created_at,
                                                          "MMM Do, h:mm a"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "pt-0  pt-xl-2 pb-xl-0 ml-n1 ",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-row", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Read",
                                                expression: "'Read'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn-icon  text-primary",
                                            attrs: {
                                              size: "23",
                                              variant: "outline-white"
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass:
                                                "cart-item-remove cursor-pointer",
                                              attrs: {
                                                icon: "CheckCircleIcon",
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ])
            ]
          )
        : _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("b-card", { staticClass: "d-flex align-items-center" }, [
                _c("div", { staticClass: "m-5" }, [
                  _c(
                    "div",
                    { staticClass: "mb-2" },
                    [
                      _c("b-img", {
                        staticClass: "mr-1",
                        attrs: {
                          src: require("@/assets/images/pages/bell.png"),
                          alt: "browser img",
                          size: "70"
                        }
                      })
                    ],
                    1
                  ),
                  _c("h5", [_vm._v("No Notifications")])
                ])
              ])
            ],
            1
          ),
      _c("li", { staticClass: "dropdown-menu-footer m-1" }, [
        _vm.un_read_notification.length > 0
          ? _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    attrs: { block: "", variant: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.readAll()
                      }
                    }
                  },
                  [_vm._v(" Read all notifications ")]
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(255, 255, 255, 0.15)",
                        expression: "'rgba(255, 255, 255, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    attrs: { disabled: true, block: "", variant: "primary" }
                  },
                  [_vm._v("Read all notifications ")]
                )
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }