var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "dropdown-notification",
      staticStyle: { "list-style-type": "none" },
      attrs: { "menu-class": "dropdown-menu-media", right: "" },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function() {
            return [
              _c("feather-icon", {
                staticClass: "text-body test3 hoverable",
                attrs: {
                  "badge-classes": "bg-danger",
                  icon: "GridIcon",
                  size: "21"
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "pl-2 pr-2 mt-2 mb-2" }, [
        _c("div", { staticClass: "hoverable" }, [
          _c("a", [
            _vm.checkIsSalriedStaffForTab()
              ? _c(
                  "div",
                  {
                    staticClass: "d-inline-flex",
                    attrs: { id: "listItems" },
                    on: { click: _vm.changeSalaried }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        alt: "",
                        width: "50px",
                        src: require("@/assets/images/pages/iconac.png")
                      }
                    }),
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-10" }, [
                          _c("div", { staticClass: "subTopic" }, [
                            _c("b", [_vm._v("Staff App")])
                          ]),
                          _vm.practiceDetails
                            ? _c("div", { staticClass: "subDescription" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.practiceDetails.name) + " "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "col-md-2" }, [
                          _vm.checkIsSalaried()
                            ? _c("div", { staticClass: "status ml-2" }, [
                                _c("b", [_vm._v("*Active")])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "hoverable" }, [
          _c("a", [
            _vm.checkIsAdminForTab()
              ? _c(
                  "div",
                  {
                    staticClass: "d-inline-flex",
                    on: { click: _vm.changeAdmin }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        alt: "",
                        width: "50px",
                        src: require("@/assets/images/pages/iconab.png")
                      }
                    }),
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-10" }, [
                          _c("div", { staticClass: "subTopic" }, [
                            _c("b", [_vm._v("Practice App")])
                          ]),
                          _vm.practiceDetails
                            ? _c("div", { staticClass: "subDescription" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.practiceDetails.name) + " "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "col-md-2" }, [
                          _vm.checkIsAdmin()
                            ? _c("div", { staticClass: "status ml-2" }, [
                                _c("b", [_vm._v("*Active")])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }