<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21"/>
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 ">
      <h3 class=" ml-1 mb-0">{{ $route.meta.title }}</h3>

      <!--      <dark-Toggler class="d-none d-lg-block" />-->
    </div>

    <!-- Staff Bank Leaves -->

    <!--
            <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex ">

              <b-alert
                  :show="true"
                  class="mb-0  rounded"
                  variant="primary"
              >
                <div class="alert-body text-dark">
                              <feather-icon
                                    class=" text-primary"
                                    icon="CheckCircleIcon"
                                    size="18"
                                />
                 <span class="ml-1">Leave on Mar 13 - 14, 2021 was approved! </span>
                 <span class="ml-5"/> <span class="ml-5">
                   <feather-icon
                class="ml-5 cursor-pointer"
                icon="XIcon"
                size="16"
            />
                 </span>
                </div>
              </b-alert>

        </div> -->

    <!-- Message Icon -->

    <!--    <div class="demo-inline-spacing  bg-transparent mt-n2 ">
          <feather-icon
            icon="MessageSquareIcon"
            size="18"
            class="text-dark"
            @click="$router.push('/chat')"
          />
        </div>-->

    <!-- BellIcon -->

    <div class="demo-inline-spacing  bg-transparent mt-n1 ">
      <NotificationIcon/>
    </div>

    <!--    App Icon-->
    <div class="demo-inline-spacing  bg-transparent mt-n1 ">
      <div class="hoverable">
        <AppDropdown v-if="permissions"/>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <attendance-dropdown />-->
      <b-nav-item-dropdown
          v-if="user"
          class="dropdown-user"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <!--            <p
                          v-if="currentUser"
                          class="user-name font-weight-bolder mb-0"
                        >
                          {{ currentUser.user.name }}

                        </p>-->
            <p class="user-name  mb-0 font-weight-700">
              <!--              <span class="text-white">  {{ getPcnAndNetwork[0].pcn.id }}</span>-->

              {{ user.first_name + ' ' + user.last_name }}
            </p>
            <span class="user-status">{{ practiceDetails.name }} </span>
          </div>
          <b-avatar
              :src="getUserImage(user.id)"
              badge
              badge-variant="success"
              class="badge-minimal"
              size="40"
              variant="light-primary"
          />
        </template>

        <!-- <b-dropdown-item
          id="nav_dropdown"
          link-class="d-flex align-items-center"
          @click="profileAccountChange()"
        >
          <feather-icon class="mr-50" icon="FileTextIcon" size="16" />
          <span>Account Dashboard</span>
        </b-dropdown-item> -->
        <!--
                <b-dropdown-item
                  link-class="d-flex align-items-center"
                  @click="goToProfile()"
                >
                  <feather-icon class="mr-50" icon="UserIcon" size="16" />
                  <span>Profile</span>
                </b-dropdown-item>

                <b-dropdown-divider /> -->

        <!-- <div v-if="getPcnAndNetwork.length !== 0">
          <span
            ><div class="ml-1 my-1"><h5>Switch to</h5></div></span
          >
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            style="height: 150px;border: 1px solid #E5E5E5;border-radius: 4px;"
            tagname="li"
          >
            <b-dropdown-item v-for="pcn in getPcnAndNetwork" :key="pcn.id">
              <span @click="switchToPcn(pcn.pcn)"> {{ 'PCN ' + pcn.pcn.name.slice(0, 15) + (pcn.pcn.name.length > 15 ? "..." : "") }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="practice in getPracticeAndNetwork"
              :key="practice.id"
            >
              <span @click="switchToPractice(practice)">{{
                'MP ' + practice.name.slice(0, 15) + (practice.name.length > 15 ? "..." : "")
              }}</span>
            </b-dropdown-item>
          </vue-perfect-scrollbar>
        </div> -->

        <!-- <div v-if="getPracticeAndNetwork.length !== 0">
          <span>
            <div class="ml-1 my-1"><h5>Switch to Practices</h5></div></span
          >

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            style="height: 120px;border: 1px solid #E5E5E5;border-radius: 4px;"
            tagname="li"
          >
            <b-dropdown-item
              v-for="practice in getPracticeAndNetwork"
              :key="practice.id"
            >
              <span @click="switchToPractice(practice)">{{
                practice.name.slice(0, 15) + (practice.name.length > 15 ? "..." : "")
              }}</span>
            </b-dropdown-item>
          </vue-perfect-scrollbar>
        </div> -->

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="localLogout"
        >
          <feather-icon class="mr-50" icon="LogOutIcon" size="16" />
          <span>Logout</span>
        </b-dropdown-item> -->


        <div>
          <div>
            <div class="userImg">
              <b-avatar
                  size="60"
                  variant="light-primary"
                  badge
                  :src="getUserImage(user.id, true)"
                  class="badge-minimal align-items-center"
                  badge-variant="success"
              />
            </div>
            <div class="userName">
              <b>{{ user.first_name }} {{ user.last_name }}</b>
            </div>
            <div class="userMail">
              {{ user.email }}
            </div>
            <div class="goToDashboard">
              <!-- <p><button @click="profileAccountChange()" class="text-info">Account Dashboard</button></p> -->
            </div>
            <div class="viewUserAccount" v-if="checkStatus()">
              <p>
                <button @click="$router.push(`/staff-bank/${user.id}`)" class="btn manageBtn">Manage your account
                </button>
              </p>
            </div>

            <b-dropdown-divider/>
            <div class="ItemsStylish">
              <!-- <div class="d-inline-flex" id="listItems" v-if="checkIsSalriedStaffForTab()" @click="changeSalaried">
                <img
                    alt=""
                    width="50px"
                    class="img-fluid"
                    src="@/assets/images/pages/iconac.png"
                />
                <div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="subTopic"><b>Staff App</b></div>
                      <div class="subDescription">{{practiceDetails.name}}</div>
                    </div>
                    <div class="col-md-2">
                      <div class="status" v-if='checkIsSalaried()' ><b>*Active</b></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-inline-flex" v-if="checkIsAdminForTab()" @click="changeAdmin">
                <img
                    alt=""
                    width="50px"
                    class="img-fluid"
                    src="@/assets/images/pages/iconab.png"
                />
                <div>
                  <div class="row">
                    <div class="col-md-10">
                      <div class="subTopic"><b>Practice App</b></div>
                      <div class="subDescription">{{practiceDetails.name}}</div>
                    </div>
                    <div class="col-md-2">
                      <div class="status" v-if='checkIsAdmin()'><b>*Active</b></div>
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col">
                  <p class="dashboaerdLink"><a @click="profileAccountChange()" class="text-info">Go to Account
                    Dashboard</a></p>
                </div>

              </div>
            </div>
            <b-dropdown-divider/>
            <div class="logoutButton">
              <b-button
                  class="btn manageBtn"
                  type="submit"
                  variant="white"
                  @click="localLogout()"
              >
                Logout of all accounts
              </b-button>
            </div>
          </div>
        </div>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BAlert,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import {mapActions, mapGetters} from 'vuex'
import NotificationIcon from './NotificationDropdown'
import profile from '@/apis/modules/profile'
import practiceAPI from '@/apis/modules/practice'
import {mpAppUrl, pcnAppUrl, loginAppUrl} from '@/constants/config'
import Cookies from 'js-cookie'
import AppDropdown from '@/layouts/components/AppDropdown'

export default {
  components: {
    AppDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    NotificationIcon,
    VuePerfectScrollbar,
    BAlert,
    BRow,
    BCol,
    BButton,

    // Navbar Components
    DarkToggler
  },

  data () {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 20
    }
    return {
      is_admin: '',
      mpAppUrl,
      pcnAppUrl,
      user: '',
      practiceDetails: '',
      perfectScrollbarSettings
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters([
      'permissions',
      'currentUser',
      'getCurrentPractice',
      'getPracticeAndNetwork',
      'getPcnAndNetwork'
    ])

  },
  methods: {
    //this used to display display tabs or tab
    checkIsAdminForTab () {
      this.is_admin = JSON.parse(sessionStorage.getItem('currentUserSelectedPermission'))[0]
      if (this.permissions.length === 2 || this.permissions[0] === 'practice-admin') {
        return true
      } else {
        return false
      }
    },

//this used to display display tabs or tab
    checkIsSalriedStaffForTab () {
      this.is_admin = JSON.parse(sessionStorage.getItem('currentUserSelectedPermission'))[0]
      if (this.permissions.length === 2 || this.permissions[0] === 'salaried-staff') {
        return true
      } else {
        return false
      }
    },

    //this used to display active message
    checkIsAdmin () {
      this.is_admin = JSON.parse(sessionStorage.getItem('currentUserSelectedPermission'))[0]
      if (this.is_admin === 'practice-admin')
        return true
      else
        return false
    },

    //this used to display active message
    checkIsSalaried () {
      this.is_admin = JSON.parse(sessionStorage.getItem('currentUserSelectedPermission'))[0]
      if (this.is_admin === 'salaried-staff')
        return true
      else
        return false
    },
    async switchToPractice (practice) {
      sessionStorage.removeItem('practice_id')
      if (this.permissions.includes('practice-admin')) {
        window.open(
            `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
            '_self'
        )
      } else {
        window.open(
            `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
            '_self'
        )
      }
    },
    async switchToPcn (pcn) {
      sessionStorage.removeItem('auth_pcn_id')
      window.open(`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.id}`, '_blank')
    },
    goToProfile () {
      if (
          this.$route.name === 'staff-bank.single' &&
          parseInt(this.$route.params.id) === this.user.id
      )
        return
      setTimeout(() => {
        this.$router.push(`/staff-bank/${this.user.id}`)
      }, 100)
    },
    profileAccountChange () {
      // window.location.href = `https://www.hyre-account.locumboxapp.com/`

      window.open(loginAppUrl, '_blank')
    },
    ...mapActions(['logout']),
    async localLogout () {
      await this.logout()
      // window.location.href = `https://www.hyre-account.locumboxapp.com/`
      window.location.href = loginAppUrl
    },
    async getAuthUser () {
      try {
        const response = await profile.getCurrentUser()
        this.user = response.data.data
        this.$store.state.userDetails = response.data.data
      } catch (error) {
        // this.convertAndNotifyError(error);
      }
    },
    async getPracticeDetails () {
      try {
        const practiceResponse = await practiceAPI.getPracticeDetails()
        this.practiceDetails = practiceResponse.data.data
      } catch (error) {
        this.convertAndNotifyError(error);
        // await this.localLogout()
      }

    },
    changeSalaried () {
      this.$root.$emit('changeAsSalariedStaff')
    },
    changeAdmin () {
      this.$root.$emit('changeAsPracticeAdmin')
    },
    checkStatus () {
      this.is_admin = JSON.parse(sessionStorage.getItem('currentUserSelectedPermission'))[0]
      return this.is_admin === 'salaried-staff' ? false : true
    }

  },
  async mounted () {
    await this.getPracticeDetails()
    await this.getAuthUser()

  }
}
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  width: 21rem;
}

.userImg {
  margin-left: 115px;
  margin-top: 5px;
}

.subTopic {
  margin-top: 14px;
  font-size: 13px;
  color: black;
}

.subDescription {
  font-size: 8px;
  margin-top: 5px;
  color: rgb(131, 127, 127);
}

.userName {
  text-align: center;
  color: black;
  margin-top: 15px;
  font-size: 15px;
}

.userMail {
  text-align: center;
  margin-top: 3px;
  font-size: 12px;
}

.viewUserAccount {
  text-align: center;
  font-size: 12px;

}

.status {
  margin-top: 18px;
  font-size: 10px;
  margin-left: 10px;
  float: left;
  color: darkgreen;
}

.ItemsStylish {
  cursor: pointer;
  text-align: center;
}

.logoutButton {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.goToDashboard {
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
}

.manageBtn {
  border-radius: 12px;
  background: transparent;
  border-color: rgb(75, 75, 75);
  height: 35px;
  padding-bottom: 7px;
  padding-top: 6px;
  color: black;
}

.dashboaerdLink {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hoverable:hover {
  background-color: #f2f2f2;
}

</style>
