<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media"
    style="list-style-type: none"
    right
  >
    <template #button-content class="hoverable">
      <!--      :badge="un_read_notification.length"-->
      <feather-icon
        badge-classes="bg-danger"
        class="text-body test3 hoverable"
        icon="GridIcon"
        size="21"
      />
    </template>

    <!-- Header -->

    <!--    <li class="dropdown-menu-header">-->
    <!--      <div class="dropdown-header d-flex">-->
    <!--        <h3 class="notification-title m-1 mr-auto font-weight-bolder dark">-->
    <!--          Switch Application-->
    <!--        </h3>-->
    <!--      </div>-->
    <!--    </li>-->

    <!-- Notifications -->

    <div class="pl-2 pr-2 mt-2 mb-2">
      <div class="hoverable">
        <a>
          <div
            class="d-inline-flex"
            id="listItems"
            v-if="checkIsSalriedStaffForTab()"
            @click="changeSalaried"
          >
            <img
              alt=""
              width="50px"
              class="img-fluid"
              src="@/assets/images/pages/iconac.png"
            />
            <div>
              <div class="row">
                <div class="col-md-10">
                  <div class="subTopic"><b>Staff App</b></div>
                  <div class="subDescription" v-if="practiceDetails">
                    {{ practiceDetails.name }}
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="status ml-2" v-if="checkIsSalaried()">
                    <b>*Active</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="hoverable">
        <a>
          <div
            class="d-inline-flex"
            v-if="checkIsAdminForTab()"
            @click="changeAdmin"
          >
            <img
              alt=""
              width="50px"
              class="img-fluid"
              src="@/assets/images/pages/iconab.png"
            />
            <div>
              <div class="row">
                <div class="col-md-10">
                  <div class="subTopic"><b>Practice App</b></div>
                  <div class="subDescription" v-if="practiceDetails">
                    {{ practiceDetails.name }}
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="status ml-2" v-if="checkIsAdmin()">
                    <b>*Active</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  VBTooltip,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import notification from "@/apis/modules/notifications";
import practice from "@/apis/modules/practice";
import MomentMixin from "@/mixins/MomentMixin";
import { loginAppUrl, mpAppUrl, pcnAppUrl } from "@/constants/config";
import practiceAPI from "@/apis/modules/practice";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    VBTooltip,
  },
  mixins: [MomentMixin],

  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      practiceDetails: "",
      is_admin: "",
    };
  },
  computed: {
    ...mapGetters([
      "permissions",
      "currentUser",
      "getCurrentPractice",
      "getPracticeAndNetwork",
      "getPcnAndNetwork",
    ]),
  },
  methods: {
    async getPracticeDetails() {
      try {
        const practiceResponse = await practiceAPI.getPracticeDetails();
        this.practiceDetails = practiceResponse.data.data;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    changeSalaried() {
      this.$root.$emit("changeAsSalariedStaff");
    },
    changeAdmin() {
      this.$root.$emit("changeAsPracticeAdmin");
    },

    //this used to display display tabs or tab
    checkIsAdminForTab() {
      // this.is_admin = JSON.parse(sessionStorage.getItem("currentUserSelectedPermission"))[0]
      if (
        this.permissions.length === 2 ||
        this.permissions[0] === "practice-admin"
      ) {
        return true;
      } else {
        return false;
      }
    },
    //this used to display display tabs or tab
    checkIsSalriedStaffForTab() {
      // this.is_admin = JSON.parse(sessionStorage.getItem("currentUserSelectedPermission"))[0]
      if (
        this.permissions.length === 2 ||
        this.permissions[0] === "salaried-staff"
      ) {
        return true;
      } else {
        return false;
      }
    },
    //this used to display active message
    checkIsAdmin() {
      // this.is_admin = JSON.parse(sessionStorage.getItem("currentUserSelectedPermission"))[0]
      if (this.is_admin === "practice-admin") return true;
      else return false;
    },

    //this used to display active message
    checkIsSalaried() {
      // this.is_admin = JSON.parse(sessionStorage.getItem("currentUserSelectedPermission"))[0]
      if (this.is_admin === "salaried-staff") return true;
      else return false;
    },
    async switchToPractice(practice) {
      // sessionStorage.removeItem('practice_id');
      if (this.permissions.includes("practice-admin")) {
        window.open(
          `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
          "_blank"
        );
      } else {
        window.open(
          `${mpAppUrl}/my-shifts?practice_id=${practice.id}`,
          "_blank"
        );
      }
    },
    async switchToPcn(pcn) {
      // sessionStorage.removeItem('auth_pcn_id');
      window.open(`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.id}`, "_blank");
    },
  },
  beforeMount() {
    this.is_admin = JSON.parse(
      sessionStorage.getItem("currentUserSelectedPermission")
    )[0];
  },

  mounted() {
    this.getPracticeDetails();
  },
};
</script>

<style>
#__BVID__160__BV_toggle_:hover {
  /*color: red !important;*/
}
element.style {
  list-style: none !important;
}

.status {
  margin-top: 18px;
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
  float: left;
  color: darkgreen;
}

.hoverable:hover {
  background-color: #f2f2f2;
}
</style>
