import api from '../axios'
const resource = '/api/mp/'

export default {

  getNotifications: (id) => api.get(`${resource}notifications?`),

  ReadNotification: ( id, payload) => api.put(`${resource}notifications/read/${id}`, payload),

  DeleteNotification: (id) => api.delete(`${resource}notifications/delete/${id}`),

  ReadAllNotifications: () => api.put(`${resource}notifications/read-all`),


}
